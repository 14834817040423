<template>
  <BPageLoader v-if="isLoading" />
  <div class="relative" v-else>
    <div
      v-if="successModal"
      class="text-center w-full lg:hidden fixed h-screen bg-bg-alternate-3"
      style="z-index: 9999999999999999 !important"
    >
      <div class="flex flex-col h-full items-center justify-center">
        <successIcon />
        <p class="font-semibold mb-5 text-lg mt-8 text-text-alternate-1">
          Congratulations
        </p>
        <p class="text-sm text-text-primary">Your withdrawal was successful</p>
      </div>
    </div>
    <div class="lg:hidden">
      <BPageLoader v-if="wishListResponse.isLoading" />
      <div class="py-2 flex flex-col text-text-primary" v-else>
        <header class="px-6 py-2.5 text-lg flex text-text-alternate-1">
          <button class="focus:outline-none" @click="$router.go(-1)">
            <LeftArrowIcon class="h-6 fill-current" />
          </button>
          <h2 class="mx-auto px-3 font-semibold truncate">
            {{ wishListResponse.data.fundraiser.title }}
          </h2>
        </header>
        <main>
          <section class="px-6">
            <div
              class="
                block
                rounded
                bg-bg-alternate-8
                p-4
                sm:py-6
                mt-4
                text-text-secondary
              "
            >
              <h5 class="font-medium text-xs text-text-alternate-9">
                TOTAL AMOUNT RAISED
              </h5>
              <p class="font-bold text-2xl pt-2">
                ₦ {{ wishListResponse.data.raisedAmount }}
              </p>
            </div>

            <div
              class="
                block
                rounded
                bg-bg-alternate-2
                p-4
                sm:py-6
                mt-4
                text-text-secondary
              "
            >
              <h5 class="font-medium text-xs text-text-alternate-9">
                TOTAL AMOUNT EARNED
              </h5>
              <p class="font-bold text-2xl pt-2">
                ₦ {{ wishListResponse.data.earnedAmount }}
              </p>
            </div>

            <div
              class="
                block
                rounded
                bg-bg-alternate-8
                p-4
                sm:py-6
                mt-4
                text-text-secondary
              "
            >
              <h5 class="font-medium text-xs text-text-alternate-9">BALANCE</h5>
              <p class="font-bold text-2xl pt-2">
                ₦ {{ wishListResponse.data.earnedAmount }}
              </p>
            </div>

            <hr class="my-4 text-text-alternate-8" />
          </section>

          <section>
            <header class="overflow-x-auto bg-bg-alternate-3 px-6 mx-auto">
              <ul class="inline-flex text-xs xs:text-sm w-full">
                <li v-for="(nav, index) in tabs" :key="index">
                  <button
                    :class="[
                      'px-4 py-3 font-semibold nav-border focus:outline-none',
                      active === index
                        ? 'nav-active text-text-alternate-1'
                        : 'text-text-alternate-10',
                    ]"
                    @click="active = index"
                  >
                    {{ nav }}
                  </button>
                </li>
              </ul>
            </header>
            <div class="px-6" v-if="active === 0">
              <BPageLoader v-if="blessingsResponse.isLoading" />
              <div
                class="flex flex-grow flex-col justify-center items-center py-6"
                v-else-if="!blessingsResponse.data.length"
              >
                <span
                  class="
                    h-24
                    w-24
                    rounded-full
                    bg-bg-alternate-3
                    flex
                    items-center
                    justify-center
                    my-6
                  "
                >
                  <CashIcon
                    class="h-8 stroke-2 stroke-current"
                    style="stroke: #314b63"
                  />
                </span>
                <h4 class="my-4 text-lg font-semibold">No Blessings</h4>
                <p class="text-xs font-medium text-center">
                  Once you start receiving blessings, they will appear here.
                </p>
              </div>
              <div class="text-text-alternate-1" v-else>
                <p class="flex justify-between font-semibold mt-4 mb-2">
                  <span class="text-lg">History</span>
                </p>
                <p class="text-xs">
                  These are the list of people that donated using your link
                </p>

                <BPageLoader v-if="blessingsResponse.isLoading" />
                <div
                  class="
                    flex flex-grow flex-col
                    justify-center
                    items-center
                    py-6
                    border-t border-border-primary
                    my-3
                  "
                  v-else-if="!blessingsResponse.data.length"
                >
                  <span
                    class="
                      h-24
                      w-24
                      rounded-full
                      bg-bg-alternate-3
                      flex
                      items-center
                      justify-center
                      my-6
                    "
                  >
                    <CashIcon
                      class="h-8 stroke-2 stroke-current"
                      style="stroke: #314b63"
                    />
                  </span>
                  <h4 class="my-4 text-lg font-semibold">No Blessings Found</h4>
                </div>
                <ul class="pt-2" v-else>
                  <li
                    v-for="blessing in blessingsResponse.data"
                    :key="blessing.id"
                    class="
                      border-t border-border-primary
                      py-2
                      flex
                      items-center
                    "
                    @click="toggleShowModal(blessing)"
                  >
                    <span
                      class="
                        rounded-full
                        border border-border-primary
                        font-bold
                        bg-bg-alternate-3
                        text-sm
                        h-8
                        w-8
                        flex flex-shrink-0
                        justify-center
                        items-center
                      "
                      :style="{
                        backgroundColor: randomColor({
                          luminosity: 'light',
                          seed: `${
                            blessing.anonymous ? 'A' : blessing.firstname[0]
                          }`,
                          format: 'rgba',
                          alpha: 0.3,
                        }),
                        color: randomColor({
                          luminosity: 'bright',
                          seed: `${
                            blessing.anonymous ? 'A' : blessing.firstname[0]
                          }`,
                        }),
                      }"
                    >
                      <img
                        class="rounded-full"
                        v-if="
                          blessing.userdetail?.account.accounttype ===
                          'CORPORATE'
                        "
                        :src="blessing.userdetail.profilePhoto"
                        alt="profile_image"
                      />
                      <span v-else>
                        {{ blessing.anonymous ? 'A' : blessing.firstname[0] }}
                      </span>
                    </span>
                    <div class="ml-4 w-full">
                      <h4 class="flex justify-between text-sm py-1">
                        <span
                          class="font-semibold flex items-center"
                          v-if="
                            blessing.userdetail?.account.accounttype ===
                            'CORPORATE'
                          "
                        >
                          {{ blessing.userdetail?.username }}
                          <VerifiedIcon
                            class="h-3 pl-1 -mt-px flex-shrink-0"
                            v-if="
                              blessing.userdetail?.verificationStatus ===
                              'VERIFIED'
                            "
                          />
                        </span>
                        <span
                          class="font-semibold"
                          v-else-if="blessing.anonymous"
                          >Anonymous</span
                        >
                        <span class="font-semibold" v-else>
                          {{ blessing.firstname }} {{ blessing.lastname }}
                        </span>
                        <span class="font-bold text-text-alternate-12">
                          ₦{{ blessing.amount.toLocaleString() }}
                        </span>
                      </h4>
                      <p
                        class="
                          flex
                          justify-between
                          text-2xs text-text-alternate-7
                          font-medium
                          py-1
                        "
                      >
                        <span class="mr-2">
                          {{ dayjs(blessing.createdAt).format('DD MMM YYYY') }}
                        </span>
                        <span>
                          {{ dayjs(blessing.createdAt).format('HH:mm:ss') }}
                        </span>
                        <span class="ml-auto capitalize">
                          via
                          {{ blessing.transaction.paymentMethod.toLowerCase() }}
                        </span>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="px-6" v-else-if="active === 1">
              <BPageLoader v-if="remittancesResponse.isLoading" />
              <div
                class="flex flex-grow flex-col justify-center items-center py-6"
                v-else-if="!remittancesResponse.data.length"
              >
                <span
                  class="
                    h-24
                    w-24
                    rounded-full
                    bg-bg-alternate-3
                    flex
                    items-center
                    justify-center
                    my-6
                  "
                >
                  <UpdateIcon class="h-8 fill-current" style="fill: #314b63" />
                </span>
                <h4 class="my-4 text-lg font-semibold">No Remittance</h4>
                <p class="text-xs font-medium text-center">
                  Every remittance made on this project will appear here.
                </p>
              </div>
              <div class="text-text-alternate-1" v-else>
                <p class="flex justify-between font-semibold my-4">
                  <span class="text-lg">History</span>
                </p>
                <ul>
                  <li
                    v-for="withdrawal in remittancesResponse.data"
                    :key="withdrawal.id"
                    class="
                      border-t border-border-primary
                      py-2
                      flex
                      items-center
                    "
                  >
                    <span
                      class="
                        rounded-full
                        font-bold
                        bg-bg-alternate-3
                        text-sm
                        h-8
                        w-8
                        flex flex-shrink-0
                        justify-center
                        items-center
                      "
                      :style="{
                        backgroundColor: randomColor({
                          luminosity: 'light',
                          seed: `${authUser.firstname[0]}`,
                          format: 'rgba',
                          alpha: 0.3,
                        }),
                        color: randomColor({
                          luminosity: 'bright',
                          seed: `${authUser.firstname[0]}`,
                        }),
                      }"
                    >
                      {{ authUser.firstname[0] }}
                    </span>
                    <div class="ml-4 w-full">
                      <h4 class="flex justify-between text-sm py-1">
                        <span class="font-semibold">Promote and Earn Remittance</span>
                        <span class="font-bold text-text-alternate-14">
                          - ₦{{ withdrawal.amount.toLocaleString() }}
                        </span>
                      </h4>
                      <p
                        class="
                          flex
                          justify-between
                          text-2xs text-text-alternate-7
                          font-medium
                          py-1
                        "
                      >
                        <span class="flex">
                          <span class="mr-2">
                            {{
                              dayjs(withdrawal.createdAt).format('DD MMM YYYY')
                            }}
                          </span>
                          <span>
                            {{ dayjs(withdrawal.createdAt).format('HH:mm:ss') }}
                          </span>
                        </span>
                        <span
                          class="font-semibold"
                          :class="
                            withdrawal.status == 'PAID'
                              ? 'text-text-alternate-6'
                              : withdrawal.status == 'REJECTED' ||
                                withdrawal.status == 'UNSUCCESFUL'
                              ? 'text-text-danger'
                              : withdrawal.status == 'PENDING'
                              ? 'text-text-alternate-15'
                              : ''
                          "
                        >
                          {{ withdrawal.status }}</span
                        >
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
    <div class="hidden lg:flex justify-center pt-4">
      <div class="b-card mt-24 pt-8 px-26">
        <BPageLoader v-if="wishListResponse.isLoading" />
        <div v-else class="py-2 flex flex-col text-text-primary">
          <header class="px-6 py-2.5 text-lg flex text-text-alternate-1">
            <button class="focus:outline-none" @click="$router.go(-1)">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <h2 class="mx-auto px-3 font-semibold truncate">
              {{ wishListResponse.data.fundraiser.title }}
            </h2>
          </header>
          <main>
            <section class="px-6">
              <div
                class="
                  block
                  rounded
                  bg-bg-alternate-8
                  p-4
                  sm:py-6
                  mt-4
                  text-text-secondary
                "
              >
                <h5 class="font-medium text-xs text-text-alternate-9">
                  TOTAL AMOUNT RAISED
                </h5>
                <p class="font-bold text-2xl pt-2">
                  ₦ {{ wishListResponse.data.raisedAmount }}
                </p>
              </div>

              <div
                class="
                  block
                  rounded
                  bg-bg-alternate-2
                  p-4
                  sm:py-6
                  mt-4
                  text-text-secondary
                "
              >
                <h5 class="font-medium text-xs text-text-alternate-9">
                  TOTAL AMOUNT EARNED
                </h5>
                <p class="font-bold text-2xl pt-2">
                  ₦ {{ wishListResponse.data.earnedAmount }}
                </p>
              </div>

              <div
                class="
                  block
                  rounded
                  bg-bg-alternate-8
                  p-4
                  sm:py-6
                  mt-4
                  text-text-secondary
                "
              >
                <h5 class="font-medium text-xs text-text-alternate-9">
                  BALANCE
                </h5>
                <p class="font-bold text-2xl pt-2">
                  ₦ {{ wishListResponse.data.earnedAmount }}
                </p>
              </div>

              <!-- <button
                @click="getUserBankDetails()"
                class="
                  mt-3
                  h-10
                  border
                  w-full
                  border-btn-border-secondary
                  rounded
                  text-btn-text-secondary
                  font-bold
                "
              >
                withdraw
              </button> -->
              <hr class="my-4 text-text-alternate-8" />
            </section>

            <section>
              <header class="overflow-x-auto bg-bg-alternate-3 px-6 mx-6">
                <ul class="inline-flex text-xs xs:text-sm w-full">
                  <li v-for="(nav, index) in tabs" :key="index">
                    <button
                      :class="[
                        'px-4 py-3 font-semibold nav-border focus:outline-none',
                        active === index
                          ? 'nav-active text-text-alternate-1'
                          : 'text-text-alternate-10',
                      ]"
                      @click="active = index"
                    >
                      {{ nav }}
                    </button>
                  </li>
                </ul>
              </header>
              <div class="px-6" v-if="active === 0">
                <BPageLoader v-if="blessingsResponse.isLoading" />
                <div
                  class="
                    flex flex-grow flex-col
                    justify-center
                    items-center
                    py-6
                  "
                  v-else-if="!blessingsResponse.data.length"
                >
                  <span
                    class="
                      h-24
                      w-24
                      rounded-full
                      bg-bg-alternate-3
                      flex
                      items-center
                      justify-center
                      my-6
                    "
                  >
                    <CashIcon
                      class="h-8 stroke-2 stroke-current"
                      style="stroke: #314b63"
                    />
                  </span>
                  <h4 class="my-4 text-lg font-semibold">No Blessings</h4>
                  <p class="text-xs font-medium text-center">
                    Once you start receiving blessings, they will appear here.
                  </p>
                </div>
                <div class="text-text-alternate-1" v-else>
                  <p class="flex justify-between font-semibold mt-4 mb-2">
                    <span class="text-lg">History</span>
                  </p>

                  <BPageLoader v-if="blessingsResponse.isLoading" />
                  <div
                    class="
                      flex flex-grow flex-col
                      justify-center
                      items-center
                      py-6
                      border-t border-border-primary
                      my-3
                    "
                    v-else-if="!blessingsResponse.data.length"
                  >
                    <span
                      class="
                        h-24
                        w-24
                        rounded-full
                        bg-bg-alternate-3
                        flex
                        items-center
                        justify-center
                        my-6
                      "
                    >
                      <CashIcon
                        class="h-8 stroke-2 stroke-current"
                        style="stroke: #314b63"
                      />
                    </span>
                    <h4 class="my-4 text-lg font-semibold">
                      No Blessings Found
                    </h4>
                  </div>
                  <ul class="pt-2" v-else>
                    <li
                      v-for="blessing in blessingsResponse.data"
                      :key="blessing.id"
                      class="
                        border-t border-border-primary
                        py-2
                        flex
                        items-center
                      "
                      @click="toggleShowModal(blessing)"
                    >
                      <span
                        class="
                          rounded-full
                          border border-border-primary
                          font-bold
                          bg-bg-alternate-3
                          text-sm
                          h-8
                          w-8
                          flex flex-shrink-0
                          justify-center
                          items-center
                        "
                        :style="{
                          backgroundColor: randomColor({
                            luminosity: 'light',
                            seed: `${
                              blessing.anonymous ? 'A' : blessing.firstname[0]
                            }`,
                            format: 'rgba',
                            alpha: 0.3,
                          }),
                          color: randomColor({
                            luminosity: 'bright',
                            seed: `${
                              blessing.anonymous ? 'A' : blessing.firstname[0]
                            }`,
                          }),
                        }"
                      >
                        <img
                          class="rounded-full"
                          v-if="
                            blessing.userdetail?.account.accounttype ===
                            'CORPORATE'
                          "
                          :src="blessing.userdetail.profilePhoto"
                          alt="profile_image"
                        />
                        <span v-else>
                          {{ blessing.anonymous ? 'A' : blessing.firstname[0] }}
                        </span>
                      </span>
                      <div class="ml-4 w-full">
                        <h4 class="flex justify-between text-sm py-1">
                          <span
                            class="font-semibold flex items-center"
                            v-if="
                              blessing.userdetail?.account.accounttype ===
                              'CORPORATE'
                            "
                          >
                            {{ blessing.userdetail?.username }}
                            <VerifiedIcon
                              class="h-3 pl-1 -mt-px flex-shrink-0"
                              v-if="
                                blessing.userdetail?.verificationStatus ===
                                'VERIFIED'
                              "
                            />
                          </span>
                          <span
                            class="font-semibold"
                            v-else-if="blessing.anonymous"
                            >Anonymous</span
                          >
                          <span class="font-semibold" v-else>
                            {{ blessing.firstname }} {{ blessing.lastname }}
                          </span>
                          <span class="font-bold text-text-alternate-12">
                            ₦{{ blessing.amount.toLocaleString() }}
                          </span>
                        </h4>
                        <p
                          class="
                            flex
                            justify-between
                            text-2xs text-text-alternate-7
                            font-medium
                            py-1
                          "
                        >
                          <span class="mr-2">
                            {{
                              dayjs(blessing.createdAt).format('DD MMM YYYY')
                            }}
                          </span>
                          <span>
                            {{ dayjs(blessing.createdAt).format('HH:mm:ss') }}
                          </span>
                          <span class="ml-auto capitalize">
                            via
                            {{
                              blessing.transaction.paymentMethod.toLowerCase()
                            }}
                          </span>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="px-6" v-else-if="active === 1">
                <BPageLoader v-if="remittancesResponse.isLoading" />
                <div
                  class="
                    flex flex-grow flex-col
                    justify-center
                    items-center
                    py-6
                  "
                  v-else-if="!remittancesResponse.data.length"
                >
                  <span
                    class="
                      h-24
                      w-24
                      rounded-full
                      bg-bg-alternate-3
                      flex
                      items-center
                      justify-center
                      my-6
                    "
                  >
                    <UpdateIcon
                      class="h-8 fill-current"
                      style="fill: #314b63"
                    />
                  </span>
                  <h4 class="my-4 text-lg font-semibold">No Remittance</h4>
                  <p class="text-xs font-medium text-center">
                    Every remittance made on this project will appear here.
                  </p>
                </div>
                <div class="text-text-alternate-1" v-else>
                  <p class="flex justify-between font-semibold my-4">
                    <span class="text-lg">History</span>
                  </p>
                  <ul>
                    <li
                      v-for="withdrawal in remittancesResponse.data"
                      :key="withdrawal.id"
                      class="
                        border-t border-border-primary
                        py-2
                        flex
                        items-center
                      "
                    >
                      <span
                        class="
                          rounded-full
                          font-bold
                          bg-bg-alternate-3
                          text-sm
                          h-8
                          w-8
                          flex flex-shrink-0
                          justify-center
                          items-center
                        "
                        :style="{
                          backgroundColor: randomColor({
                            luminosity: 'light',
                            seed: `${authUser.firstname[0]}`,
                            format: 'rgba',
                            alpha: 0.3,
                          }),
                          color: randomColor({
                            luminosity: 'bright',
                            seed: `${authUser.firstname[0]}`,
                          }),
                        }"
                      >
                        {{ authUser.firstname[0] }}
                      </span>
                      <div class="ml-4 w-full">
                        <h4 class="flex justify-between text-sm py-1">
                          <span class="font-semibold">Promote and Earn Remittance</span>
                          <span class="font-bold text-text-alternate-14">
                            - ₦{{ withdrawal.amount.toLocaleString() }}
                          </span>
                        </h4>
                        <p
                          class="
                            flex
                            justify-between
                            text-2xs text-text-alternate-7
                            font-medium
                            py-1
                          "
                        >
                          <span class="flex">
                            <span class="mr-2">
                              {{
                                dayjs(withdrawal.createdAt).format(
                                  'DD MMM YYYY'
                                )
                              }}
                            </span>
                            <span>
                              {{
                                dayjs(withdrawal.createdAt).format('HH:mm:ss')
                              }}
                            </span>
                          </span>
                          <span
                            class="font-semibold"
                            :class="
                              withdrawal.status == 'PAID'
                                ? 'text-text-alternate-6'
                                : withdrawal.status == 'REJECTED' ||
                                  withdrawal.status == 'UNSUCCESFUL'
                                ? 'text-text-danger'
                                : withdrawal.status == 'PENDING'
                                ? 'text-text-alternate-15'
                                : ''
                            "
                          >
                            {{ withdrawal.status }}</span
                          >
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </div>
  </div>

  <!-- <BModal isOpen="withdrawModal" @close="withdrawModal = false"> </BModal> -->

  <BModal :isOpen="showModal" @close="showModal = false">
    <div class="py-2.5">
      <header class="border-b border-border-primary pb-2">
        <h2 class="flex justify-between text-sm">
          <span class="text-text-alternate-1 font-semibold">
            {{ detail.firstname }} {{ detail.lastname }}
          </span>
          <span class="font-bold" style="color: #017622">
            ₦{{ detail.amount.toLocaleString() }}
          </span>
        </h2>
        <div class="flex justify-between items-center">
          <div class="text-text-alternate-7 font-medium">
            <span class="text-2xs mr-2">
              {{ dayjs(detail.createdAt).format('DD MMM YYYY') }}
            </span>
            <span class="text-2xs">
              {{ dayjs(detail.createdAt).format('HH:mm:ss') }}
            </span>
          </div>
        </div>
      </header>
      <div class="text-2xs pt-4">
        <div class="py-2" v-if="detail.transaction.bankCode">
          <h4 class="text-text-alternate-7 font-medium">Bank</h4>
          <p class="py-2 font-semibold text-text-alternate-1">
            {{ getBankName(detail.transaction.bankCode) || 'N/A' }}
          </p>
        </div>
        <div class="py-2" v-else>
          <h4 class="text-text-alternate-7 font-medium">Card Type</h4>
          <p class="py-2 font-semibold text-text-alternate-1">
            {{ detail.transaction.cardType || 'N/A' }}
          </p>
        </div>
        <div class="py-2" v-if="detail.transaction.accountNumber">
          <h4 class="text-text-alternate-7 font-medium">Account Number</h4>
          <p class="py-2 font-semibold text-text-alternate-1">
            {{ detail.transaction.accountNumber }}
          </p>
        </div>
        <div class="py-2" v-else>
          <h4 class="text-text-alternate-7 font-medium">Card Number</h4>
          <p class="py-2 font-semibold text-text-alternate-1">
            {{
              detail.transaction.cardLast4Digits
                ? `**********${detail.transaction.cardLast4Digits}`
                : 'N/A'
            }}
          </p>
        </div>
        <div class="py-2">
          <h4 class="text-text-alternate-7 font-medium">Remarks</h4>
          <p class="py-2 font-semibold text-text-alternate-1">
            {{ detail.transaction.desc || 'N/A' }}
          </p>
        </div>
        <div class="pt-2">
          <h4 class="text-text-alternate-7 font-medium">
            Transaction Reference
          </h4>
          <p class="py-2 font-semibold text-text-alternate-1">
            {{ detail.transaction.transactionReference || 'N/A' }}
          </p>
        </div>
      </div>
    </div>
  </BModal>
</template>

<script>
import { ref, watch } from 'vue';
import randomColor from 'randomcolor';
import { useRoute } from 'vue-router';
import { useApi } from '@/cmp-functions/useApi';
import { banks } from '@/data/banks';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import {
  getPromotedFundraiserBlessings,
  getPromoteAndEarnRemittances,
  getUserBank,
  postWalletWithdrawal,
  getWishListById,
} from '@/services/api';
// import BarChart from './BarChart';
import BModal from '@/components/ui/BModal';
import debounce from 'lodash.debounce';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import minMax from 'dayjs/plugin/minMax';
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(minMax);

export default {
  name: 'AccountFundraiser',

  components: {
    // BarChart,
    BModal,
  },

  setup() {
    const toast = useToast();
    const tabs = ['Blessings', 'Remittances'];
    const route = useRoute();
    const store = useStore();
    const authUser = store.state.auth.authData;

    const promoteAndEarnId = ref(route.params.id);
    const fundraiserId = ref(0);

    const [wishListResponse, fetchWishList] = useApi(getWishListById);
    let [blessingsResponse, fetchBlessings] = useApi(
      getPromotedFundraiserBlessings
    );
    const [remittancesResponse, fetchRemittances] = useApi(
      getPromoteAndEarnRemittances
    );
    const [withdrawResponse, makeWithdrawals] = useApi(postWalletWithdrawal);
    const [bankResponse, fetchUserBank] = useApi(getUserBank);
    fetchWishList(promoteAndEarnId.value);

    watch(wishListResponse, ({ data }) => {
      fundraiserId.value = data.fundraiser.id;
      fetchBlessings(promoteAndEarnId.value);
      fetchRemittances(promoteAndEarnId.value, fundraiserId.value);
    });

    fetchBlessings = debounce(fetchBlessings, 250);

    const active = ref(0);
    const selected = ref('today');
    const openState = ref(false);
    const showModal = ref(false);
    const detail = ref(null);
    const searchName = ref('');
    const amount = ref('');
    const isWithdraw = ref(false);
    const showError = ref('');
    const successModal = ref(false);
    const validateAmount = (balance) => {
      if (amount.value < 5000) {
        showError.value = 'Minimum withdrawable amount is ₦5000';
      } else if (amount.value > balance) {
        showError.value = `You cannot withdraw more than ₦${balance.toLocaleString()}`;
      } else {
        showError.value = '';
      }
    };

    const toggleShowModal = (index) => {
      showModal.value = !showModal.value;
      detail.value = index;
    };

    const withdrawalFromWallet = () => {
      const payload = {
        fundraiserId,
        bankId: bankResponse.data.id,
        userId: authUser.id,
        amount: amount.value,
      };
      makeWithdrawals(payload);
    };

    watch(withdrawResponse, ({ isSuccess }) => {
      if (isSuccess)
        (isWithdraw.value = false),
          (successModal.value = true),
          setTimeout(() => {
            successModal.value = false;
          }, 2000),
          toast.success('Withdrawal request sent!');
    });

    const getUserBankDetails = () => {
      isWithdraw.value = true;
      fetchUserBank(authUser.id);
    };

    const getBankName = (bankCode) => {
      return banks.find(({ nipCode }) => nipCode === bankCode)?.name;
    };

    return {
      tabs,
      active,
      amount,
      dayjs,
      authUser,
      searchName,
      promoteAndEarnId,
      randomColor,
      isWithdraw,
      getUserBankDetails,
      remittancesResponse,
      withdrawResponse,
      getBankName,
      withdrawalFromWallet,
      selected,
      toggleShowModal,
      showModal,
      detail,
      blessingsResponse,
      wishListResponse,
      bankResponse,
      openState,
      validateAmount,
      showError,
      successModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.nav-border {
  border: 4px solid transparent;
}

.nav-active {
  border-bottom-color: #577387;
}
</style>
